<template>
  <base-nav-list>
    <v-list-item :to="routeToParentFolder">
      <v-list-item-icon>
        <!-- navigate back button -->
        <navigate-back-button
          v-if="canNavigateBack"
          :to="createModuleItemContentRoute(navigateBackId)"
          :tooltip="tooltipGoBack"
        >
        </navigate-back-button>

        <!-- Expanded record badge-icon -->
        <record-badge-icon v-show="visibleExpandIcon" :record="lastBreadcrumb">
          <template v-slot:tooltip>
            {{ tooltipExpandedRecord }}
          </template>
        </record-badge-icon>
      </v-list-item-icon>

      <!-- selected Breadcrumb-->
      <v-list-item-content>
        <v-list-item-title>{{ lastBreadcrumbName }}</v-list-item-title>
      </v-list-item-content>

      <!-- Drop down menu of parent records -->
      <v-list-item-action>
        <chevron-down-button-parents
          :breadcrumbs="computedBreadcrumbs"
          :create-target-route="createModuleItemContentRoute"
          :can-navigate="canNavigateToModuleItem"
        >
        </chevron-down-button-parents>
      </v-list-item-action>
    </v-list-item>
  </base-nav-list>
</template>

<script>
import { itemCount } from "@/utils";
import { fullRecordName } from "@/services/record/recordService";

export default {
  name: "ParentRecordNavigator",
  components: {
    BaseNavList: () => import("@/components/shared/base/BaseNavList"),
    RecordBadgeIcon: () => import("@/components/record/RecordBadgeIcon"),
    NavigateBackButton: () =>
      import("@/components/shared/core/buttons/NavigateBackButton"),
    ChevronDownButtonParents: () =>
      import("@/components/shared/core/buttons/ChevronDownButtonParents")
  },
  props: {
    /**
     * array of breadcrumbs
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}[]}
     */
    breadcrumbs: undefined,

    /**
     * route To Parent Folder
     * @type {{name: (String|string), params: {id:number}}}
     */
    routeToParentFolder: undefined,

    /**
     * function: createRecordTargetRoute(id)
     */
    createModuleItemContentRoute: {
      type: Function,
      required: true
    },

    /**
     * function: canNavigateToRecord(id)
     */
    canNavigateToModuleItem: {
      type: Function,
      required: true
    }
  },
  computed: {
    /**
     * computed Breadcrumbs
     * @return {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}[]}
     */
    computedBreadcrumbs() {
      return this.breadcrumbs ?? [];
    },

    /**
     * Get selected Breadcrumb id
     * @return {Number|number}
     */
    lastBreadcrumbId() {
      return this.lastBreadcrumb?.id ?? -1;
    },

    /**
     * Get selected Breadcrumb Name
     * @return {*|string}
     */
    lastBreadcrumbName() {
      return this.lastBreadcrumb?.name ?? "";
    },

    /**
     * navigate Back record id
     * @return {Number|number} Record id
     */
    navigateBackId() {
      const id = this.lastBreadcrumb?.parentId ?? -1;
      return id >= 0 ? id : 0;
    },

    /**
     * last Breadcrumb
     * @return {*|undefined}
     */
    lastBreadcrumb() {
      return this.breadcrumbCount > 0
        ? this.breadcrumbs[this.breadcrumbCount - 1]
        : undefined;
    },

    /**
     * Get Breadcrumb Count
     * @return {Number|number}
     */
    breadcrumbCount() {
      return itemCount(this.breadcrumbs);
    },

    /**
     * tooltip Expanded Record
     * @return {string} tooltip text
     */
    tooltipExpandedRecord() {
      return `Expanded ${this.lastBreadcrumb?.recordType}: ${this.lastBreadcrumb?.name}`;
    },

    /**
     * Get Go Back tooltip
     * @return {string}
     */
    tooltipGoBack() {
      const breadcrumb =
        (this.breadcrumbCount ?? 0) <= 1
          ? undefined
          : this.breadcrumbs[this.breadcrumbCount - 2];
      const backUp = "Navigate back";

      return breadcrumb
        ? `${backUp} to ${breadcrumb?.recordType}: ${fullRecordName(
            breadcrumb
          )}`
        : backUp;
    },

    /**
     * Can navigate Back
     * @return {boolean} true if navigatable Back
     */
    canNavigateBack() {
      return this.breadcrumbCount > 1;
    },

    /**
     * visible Expand Icon
     * @return {boolean} true if visible Expand Icon
     */
    visibleExpandIcon() {
      return !!this.lastBreadcrumb;
    }
  }
};
</script>
